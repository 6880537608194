<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refRiskyClientsCompleted'].refresh()"
    >
      <template #table>
        <b-table
          ref="refRiskyClientsCompleted"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="searchRiskyClients"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :filter="searchInput"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(client)="data">
            <div class="d-flex flex-row">
              <!-- <icon-status :icon-status="data.item" /> -->
              <status-account
                :account="data.item"
                :text="false"
              ></status-account>
              <div>
                <router-link
                  class="m-0 text-bold"
                  :class="isDarkSkin ? 'text-warning' : 'text-primary'"
                  :to="{
                    name: 'specialist-digital-dashboard',
                    params: { idClient: `${data.item.id_account}` },
                  }"
                  target="_blank"
                >
                  {{ data.item.client }}
                </router-link>
                <br v-if="data.item.user_responsible">
                <span
                  v-if="data.item.user_responsible"
                  class="text-warning"
                >
                  <tabler-icon
                    :icon="
                      data.item.user_responsible === currentUser.user_id
                        ? 'StarIcon'
                        : 'LockIcon'"
                    size="15"
                  />
                  <strong>CEO's client</strong>
                </span>
                <p class="m-0">
                  {{ data.item.mobile }}
                </p>
                <p class="m-0">
                  {{ data.item.account }}
                  <span class="text-primary" style="font-weight: bold"
                    >({{ data.item.state_eeuu }})
                  </span>
                </p>

                <span v-if="data.item.migration == 2">
                  <b-badge class="font10" variant="success">Migration</b-badge>
                </span>
              </div>
            </div>
          </template>
          <template v-slot:cell(start_date)="data">
            {{ data.item.start_date | myGlobal }}
          </template>
          <template #cell(monthly_amount)="data">
            $ {{ data.item.monthly_amount }}
          </template>
          <template v-slot:cell(fee)="data">
            <div>$ {{ data.item.fee }}</div>
          </template>

          <template #cell(cr)="data">
            <div class="text-center">
              <b-badge v-if="data.item.total_cr" variant="primary">
                {{ data.item.total_cr }}
              </b-badge>
              <b-badge v-else variant="primary"> 0 </b-badge>
            </div>
          </template>

          <template v-slot:cell(rounds)="data">
            <div class="text-center">
              <b-badge v-if="data.item.total_rounds_client" variant="primary">
                {{ data.item.total_rounds_client }}
              </b-badge>
              <b-badge v-else variant="primary"> 0 </b-badge>
            </div>
          </template>

          <template v-slot:cell(tu)="data">
            <div class="text-center">
              {{ data.item.transunion }}
            </div>
          </template>
          <template v-slot:cell(note)="data">
            <div
              class="d-flex justify-content-center cursor-pointer"
              @click="openFirstNoteModal(data.item)"
            >
              <feather-icon class="text-success" size="22" icon="FileIcon" />
            </div>
          </template>
          <template v-slot:cell(ex)="data">
            <div class="text-center">
              {{ data.item.experian }}
            </div>
          </template>

          <template v-slot:cell(eq)="data">
            <div class="text-center">
              {{ data.item.equifax }}
            </div>
          </template>

          <template v-slot:cell(work_plan)="data">
            <div class="text-center">
              <b-badge v-if="data.item.total_wp" variant="primary">
                {{ data.item.total_wp }}
              </b-badge>
              <b-badge v-else variant="primary"> 0 </b-badge>
            </div>
          </template>
          <template v-slot:cell(created_at_done)="data">
            <div class="text-center">
              <span>{{ data.item.created_at_done | myGlobal }}</span>
            </div>
          </template>
          <template v-slot:cell(risk)="data">
            <div
              v-if="data.item.typerisk == null"
              style="width: 120px"
              class="d-flex justify-content-center"
            >
              <feather-icon
                class="text-info cursor pointer"
                v-b-tooltip.hover.left="'Client Risk Clasification'"
                @click="
                  openInsertCommentModal(data.item.id_account)
                "
                icon="PocketIcon"
                size="20"
              />
            </div>

            <div v-else class="d-flex justify-content-center ml-2">
              <div
                @click="openModalInsertCommentRisky(data.item)"
                v-b-tooltip.hover.top="'View Tracking'"
                class="cursor-pointer"
              >
                <b-badge style="width: 100px" :variant="getRiskyBadgeVariant(data.item.level)">{{
                  getRiskyLevelName(data.item.level)
                }}</b-badge>
              </div>

              <div class="d-flex align-items-center">
                <feather-icon
                  icon="Edit2Icon"
                  class="cursor-pointer text-warning"
                  v-b-tooltip.hover.right="'Edit Classification'"
                  style="margin-left: 5px"
                  size="13"
                  @click="
                    openInsertCommentModal(
                      data.item.id_account,
                      data.item.typerisk,
                      data.item.level
                    )
                  "
                />
              </div>
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <modal-insert-comments-risky
      v-if="modalInsertCommentsRiskyOn"
      :data="comments"
      @close="closeModalInsertCommentRisky"
    />
    <modal-insert-risky
      v-if="modalInsertComment"
      :type="typeRisk"
      :client_id="client_id"
      :level="levelRiskyClient"
      @refresh="refresh"
      @close="closeInsertCommentModal"
    />
    <credit-experts-modal
      v-if="modalCreditExperts"
      :sales-client="salesClient"
      :type-modal="2"
      @closeModal="closeCreditExpertsModal"
    />
    <modal-add-work-plan
      v-if="workPlanController"
      :id-ncr="ncrId"
      :id-work-plan="workPlanId"
      :client-name="workPlanData.client"
      :client_id="workPlanData.id_account"
      :account="workPlanData.account"
      @close="closeWorkPlanModal"
    />
    <!-- <list-all-notes-modal
      v-if="isAllNotesActive"
      :id-account="notesData.id_account"
      :name-client="notesData.client"
      @close="closeModalAllNotes"
    /> -->
    <modal-notas-credit
      v-if="creditExpertsDigitalController"
      :note-info="noteInfo"
      :from-dashboard="true"
      @hide="creditExpertsDigitalController = false"
    />
    <add-round-modal
      v-if="addRoundModal"
      :data-round="itemClient"
      :validate-modal="3"
      @close="closeModalRounds"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import RiskyService from "@/views/management/views/risky-clients/risky.service";
import ModalInsertCommentsRisky from "@/views/management/views/risky-clients/modals/ModalInsertCommentsRisky.vue";
import filterData from "@/views/specialist-digital/views/clients/components/filters.completed.data.js";
import ModalInsertRisky from "@/views/specialist-digital/views/clients/components/ModalInsertRisky";
import CrmServices from "@/views/crm/services/crm.service";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal";
import ModalAddWorkPlan from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalAddWorkPlan";
import ProcessCrServices from "@/views/specialist-digital/views/process-cr/services/process-cr.services";
import ListAllNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListAllNotesModal.vue";
import AddRoundModal from "@/views/specialist-digital/views/clients/components/AddRoundModal";
import IconStatus from "@/views/specialist-digital/views/process-cr/view/components/grids/IconStatus";
import ModalNotasCredit from "@/views/commons/components/first-notes/ModalNotasCredit";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    ModalInsertCommentsRisky,
    ModalInsertRisky,
    CreditExpertsModal,
    ModalAddWorkPlan,
    ListAllNotesModal,
    AddRoundModal,
    IconStatus,
    ModalNotasCredit,
    StatusAccount,
  },

  data() {
    return {
      levelRiskyClient: null,
      salesClient: {},
      modalCreditExperts: false,
      sellers: [],
      typeRisk: "",
      riskCLient: null,
      client_id: "",
      modalInsertComment: false,
      filters: filterData,
      comments: "",
      modalInsertCommentsRiskyOn: false,
      status: null,
      seller: null,
      program: null,
      statusip: null,
      sourcesname_id: "",
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Name of clients...",
        model: "",
      },
      fields: [
        {
          key: "client",
          label: "client",
        },
        {
          key: "start_date",
          label: "Start Date",
        },
        {
          key: "monthly_amount",
          label: "Monthly Payment",
        },
        {
          key: "fee",
          label: "fee",
        },
        {
          key: "risk",
          label: "Risky",
          thStyle: "width: 140px; text-align: center;",
        },
        {
          key: "cr",
          label: "Cr",
          thStyle: "text-align: center",
        },
        {
          key: "rounds",
          label: "Rounds",
          thStyle: "text-align: center",
        },

        {
          key: "work_plan",
          label: "Work Plan",
          thStyle: "text-align: center",
        },
        {
          key: "note",
          label: "first note",
          thStyle: "text-align: center",
        },
        {
          key: "tu",
          label: "Tu",
          thStyle: "text-align: center",
        },
        {
          key: "ex",
          label: "Ex",
          thStyle: "text-align: center",
        },
        {
          key: "eq",
          label: "Eq",
          thStyle: "text-align: center",
        },
        {
          key: "created_at_done",
          label: "Done At",
          thStyle: "text-align: center",
        },
      ],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      selectedItem: [],
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "name",
      sortDesc: true,
      searchInput: "",
      mood: "",
      workPlanData: {},
      workPlanController: false,
      ncrId: null,
      workPlanId: null,
      isAllNotesActive: false,
      notesData: {},
      addRoundModal: false,
      itemClient: {},
      creditExpertsDigitalController: false,
      noteInfo: {
        nameProgram: "",
        created: "",
        nameClient: "",
        type: 1,
        editModal: false,
        statusSale: 4,
        notSeller: false,
        saleId: "",
        sourcesName: "",
        pack: "",
      },
    };
  },

  computed: {
    ...mapState({
      captured: (state) => state["crm-store"].capturedCrm,
      sources: (state) => state["crm-store"].sources,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    closeModalRounds() {
      this.addRoundModal = false;
    },

    openModalRounds(data) {
      this.itemClient = {
        id: data.id_account,
        account: data.account,
        clientName: data.client,
      };
      this.addRoundModal = true;
    },
    closeModalAllNotes() {
      this.isAllNotesActive = false;
    },
    // openAllNotes(data) {
    //   this.notesData = data
    //   this.isAllNotesActive = true
    // },
    openFirstNoteModal(data) {
      this.noteInfo.nameProgram = data.program;
      this.noteInfo.created = data.creates;
      this.noteInfo.nameClient = data.client;
      this.noteInfo.saleId = data.id;
      this.noteInfo.sourcesName = data.sourcesname;
      this.noteInfo.pack = data.pack;
      this.creditExpertsDigitalController = true;
    },
    closeWorkPlanModal() {
      this.idWorkPlan = "";
      this.workPlanController = false;
    },

    openWorkPlanModal(data) {
      this.workPlanData = data;
      this.ncrId = data.ncr_id;
      this.workPlanId = data.work_plan_id;
      this.workPlanController = true;
    },

    openCreditExpertsModal(data) {
      this.salesClient = data;
      this.modalCreditExperts = true;
    },
    closeCreditExpertsModal() {
      this.modalCreditExperts = false;
    },

    async getSellers() {
      const tempSellers = await CrmServices.getSellersCrm(2, {
        roles: "[]",
        type: "0",
      });

      const formatedSellers = tempSellers.map((seller) => ({
        id: seller.id,
        label: seller.user_name,
      }));

      this.sellers = [{ id: 0, label: "All" }];
      this.sellers.push(...formatedSellers);

      this.filters[3].options = this.sellers;
      this.filters[3].model = null;
    },
    refresh() {
      this.$refs.refRiskyClientsCompleted.refresh();
    },
    openInsertCommentModal(data, type, level) {
      this.levelRiskyClient = level;
      this.modalInsertComment = true;
      this.client_id = data;
      this.typeRisk = type;
    },
    closeInsertCommentModal() {
      this.modalInsertComment = false;
    },
    openModalInsertCommentRisky(data) {
      this.modalInsertCommentsRiskyOn = true;
      this.comments = data;
    },
    closeModalInsertCommentRisky() {
      this.modalInsertCommentsRiskyOn = false;
    },

    // eslint-disable-next-line consistent-return
    async insertTrackingClientDigital(info) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          const params = {
            client_id: info.id_account,
            user_id: this.currentUser.user_id,
          };

          // eslint-disable-next-line no-shadow
          const data = await ProcessCrServices.insertTrackingDigitalClients(
            params
          );
          if (data.status === 200) {
            this.showSuccessSwal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    getRiskyBadgeVariant(level) {
      switch (level) {
        case 1:
          return "info";

        case 2:
          return "warning";

        case 3:
          return "danger";

        default:
          return "success";
      }
    },

    getRiskyLevelName(level) {
      switch (level) {
        case 1:
          return "RISK - LOW";

        case 2:
          return "RISK - MEDIUM";

        case 3:
          return "RISK - HIGH";

        default:
          return "NO RISK";
      }
    },

    async searchRiskyClients(ctx) {
      const params = {
        per_page: ctx.perPage,
        orderby: 2,
        page: ctx.currentPage,
        order: ctx.sortDesc == 1 ? "desc" : "asc",
        text: this.filterPrincipal.model,
        from: this.filters[0].model,
        to: this.filters[1].model,
        from_done: this.filters[2].model,
        to_done: this.filters[3].model,
        type_risky: null,
        new: 1,
      };

      const data = await RiskyService.getDigitalClients(params);
      this.items = data.data.data;
      this.startPage = data.data.from;
      this.paginate.currentPage = data.data.current_page;
      this.paginate.perPage = data.data.per_page;
      this.nextPage = this.startPage + 1;
      this.endPage = data.data.last_page;
      this.totalRows = data.data.total;
      this.toPage = data.data.to;
      return this.items || [];
    },
  },
};
</script>

<style scoped>
.bigger {
  width: 1.35em !important;
  height: 1.35em !important;
}
.pointer {
  cursor: pointer;
}
.button {
  padding: 0;
  margin: 5px 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
</style>
